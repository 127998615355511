import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SingleMember from './SingleMember';

export default function MeetOurTeam() {
  const data = useStaticQuery(graphql`
    {
      allSanityMembers(sort: { fields: order }) {
        nodes {
          order
          name
          designation
          whatYouDo
          pic {
            asset {
              gatsbyImageData
            }
          }
          petPic {
            asset {
              gatsbyImageData
            }
            alt
          }
          socials {
            linkedIn
            phone
            email
          }
          _rawBio
        }
      }
    }
  `);
  const allMembers = data.allSanityMembers.nodes;

  const serializers = {
    marks: {
      /* eslint-disable */
      link: ({ children, mark }) => (
        <a href={mark.href} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
      /* eslint-enable */
    },
  };

  return (
    <>
      <div id="meet-our-team">
        <div className="wrapper centered">
          <h2>Meet our team</h2>

          <div className="all-members">
            {allMembers.map((member, i) => {
              const {
                name,
                designation,
                whatYouDo,
                pic,
                petPic,
                socials,
                _rawBio,
              } = member;
              return (
                <SingleMember
                  name={name}
                  designation={designation}
                  whatYouDo={whatYouDo}
                  pic={pic}
                  petPic={petPic?.asset.gatsbyImageData}
                  petAlt={petPic?.alt}
                  socials={socials}
                  _rawBio={_rawBio}
                  serializers={serializers}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
