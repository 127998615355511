import React from 'react';

export default function IconPhone() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.16652 8.21602C5.76639 11.7 8.60916 14.4614 12.1382 15.9594L12.7049 16.2119C13.3324 16.4913 14.0384 16.5403 14.6985 16.3502C15.3586 16.1601 15.9304 15.7431 16.3132 15.1727L17.054 14.0694C17.1699 13.8963 17.2169 13.6862 17.1856 13.4802C17.1543 13.2743 17.047 13.0876 16.8848 12.9569L14.3749 10.9319C14.2874 10.8614 14.1867 10.8093 14.0786 10.7787C13.9706 10.7482 13.8575 10.7398 13.7461 10.754C13.6347 10.7682 13.5274 10.8049 13.4305 10.8616C13.3336 10.9184 13.2492 10.9942 13.1824 11.0844L12.4057 12.1319C10.412 11.1472 8.79829 9.53319 7.81402 7.53936L8.86068 6.76269C8.95089 6.69582 9.02665 6.61142 9.08342 6.51454C9.14019 6.41766 9.17679 6.31031 9.19104 6.19893C9.20528 6.08756 9.19687 5.97445 9.1663 5.8664C9.13574 5.75836 9.08366 5.6576 9.01318 5.57019L6.98818 3.06019C6.85743 2.89807 6.67071 2.79078 6.4648 2.75947C6.25889 2.72816 6.04872 2.77509 5.87568 2.89102L4.76485 3.63602C4.19102 4.02084 3.77242 4.59687 3.58364 5.26149C3.39486 5.92612 3.44815 6.63618 3.73402 7.26519L4.16652 8.21602Z" />
      </svg>
    </>
  );
}
