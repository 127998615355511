import React from 'react';

export default function IconEmail() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.59426 3.30793C8.52619 3.04579 11.4757 3.04579 14.4076 3.30793L15.6726 3.4221C16.2849 3.47674 16.8608 3.73617 17.3075 4.15849C17.7542 4.5808 18.0454 5.14135 18.1343 5.7496C18.5463 8.56796 18.5463 11.4312 18.1343 14.2496C18.0454 14.8578 17.7542 15.4184 17.3075 15.8407C16.8608 16.263 16.2849 16.5225 15.6726 16.5771L14.4076 16.6904C11.4757 16.9526 8.52619 16.9526 5.59426 16.6904L4.32926 16.5771C3.71699 16.5225 3.14102 16.263 2.69436 15.8407C2.2477 15.4184 1.95643 14.8578 1.8676 14.2496C1.45559 11.4312 1.45559 8.56796 1.8676 5.7496C1.95643 5.14135 2.2477 4.5808 2.69436 4.15849C3.14102 3.73617 3.71699 3.47674 4.32926 3.4221L5.59426 3.30877V3.30793ZM4.88093 5.6996C4.78573 5.64826 4.67883 5.6225 4.57069 5.62485C4.46255 5.6272 4.35687 5.65757 4.26399 5.713C4.1711 5.76843 4.0942 5.84701 4.04078 5.94107C3.98737 6.03513 3.95928 6.14144 3.95926 6.2496V14.1663C3.95926 14.332 4.02511 14.491 4.14232 14.6082C4.25953 14.7254 4.4185 14.7913 4.58426 14.7913C4.75002 14.7913 4.90899 14.7254 5.0262 14.6082C5.14341 14.491 5.20926 14.332 5.20926 14.1663V7.29627L9.70426 9.71627C9.88926 9.81627 10.1126 9.81627 10.2976 9.71627L14.7926 7.29627V14.1663C14.7926 14.332 14.8584 14.491 14.9757 14.6082C15.0929 14.7254 15.2518 14.7913 15.4176 14.7913C15.5834 14.7913 15.7423 14.7254 15.8595 14.6082C15.9767 14.491 16.0426 14.332 16.0426 14.1663V6.2496C16.0426 6.14144 16.0145 6.03513 15.9611 5.94107C15.9077 5.84701 15.8308 5.76843 15.7379 5.713C15.645 5.65757 15.5393 5.6272 15.4312 5.62485C15.323 5.6225 15.2161 5.64826 15.1209 5.6996L10.0009 8.45627L4.88093 5.6996Z"
        />
      </svg>
    </>
  );
}
