import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function ClientLogos({ noTopPad = false }) {
  const data = useStaticQuery(graphql`
    {
      sanityAboutpage {
        logos {
          alt
          asset {
            gatsbyImageData
          }
        }
      }
    }
  `);
  const allClients = data.sanityAboutpage.logos;

  return (
    <>
      <section className={`client-logos ${noTopPad && 'no-top-pad'}`}>
        <div className="wrapper centered">
          <div className="all-logos">
            {allClients.map((client, i) => (
              <div className="img" key={i}>
                <GatsbyImage
                  image={client.asset.gatsbyImageData}
                  alt={client.alt}
                  objectFit="contain"
                  className="invert-color"
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
